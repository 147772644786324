@import 'src/assets/scss/_variables.scss';

.dropdown-toggle::after {
    display: none !important;
}

.grid-view ul {
    padding-left: 0.4rem;
}

.folder-icon {
    background-image: url('../images/folder-icon.png');
    background-repeat: no-repeat;
    display: block;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.active-view {
    background-color: $primary-color;
    color: #FFFFFF;
}

.view-icon:hover {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.dropdown-item:hover {
    background-color: #eee !important;
}

.list-view table thead {
    background-color: $primary-color !important;
    color: #fff !important;
}


.search-files .form-control {
    background-color: transparent !important;
}


.form-control:focus,
.form-select:focus {
    box-shadow: 0 0 0 0 !important;
}

.dropdown-menu.show {
    border: none !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.view-icon-space {
    margin-right: 12px;
}


.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #2088e6 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    border-radius: 0 !important;
  }
  
  .nav-tabs .nav-link {
    font-weight: 500 !important;
    font-size: 15px !important;
    border-radius: 0 !important;
  
  }
  .nav-tabs .nav-link {
    color: #41464b !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.nav-tabs .nav-link:not(.active):hover {
    color: $primary-color !important;
    border-radius: 0 !important;
  }
.dropdown-icons .fa-solid {
    color: #000000c7;
}

.tree-view {
    height: calc(100vh - 65px);
    border-right: 1px solid #ccc;
    background-color: #fff;
    scrollbar-width: thin;
}

.search-icon {
    color: #7a838a;
    position: absolute;
    top: 26px;
    left: 11px;
}

.pl-30 {
    padding-left: 30px !important;
}

.search-files {
    color: #7a838a;
}

@media (max-width: 991.98px) {
    .search-files {
        margin-bottom: 0.5rem;
    }
}


.trash-toggle {
    position: fixed;
    bottom: 50px;
    width: 278px;
    padding: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input.default:checked+.slider {
    background-color: #c62828;
}


input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.tree-view-section {
    height: calc(100vh - 205px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

}
.grid-view-section {
    height: calc(100vh - 240px);
    overflow-y: auto;
    will-change: transform;
    -webkit-overflow-scrolling: touch;
    position: relative;
    contain: strict; /* Improves scrolling performance */
  }

cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
    contain: content;
    margin: 0;
    padding: 0;
  }
  
  /* Make sure ul and section don't add any extra spacing */
  .grid-view-section section,
  .grid-view-section ul {
    margin: 0;
    padding: 0;
  }

.dropdown-item:active {
    background-color: $primary-color !important;
}

.w-150 {
    width: 120px;
}

table {
    white-space: nowrap;
}



.disable {
    pointer-events: none;
    opacity: 0.3;
}

.table-height {
    height: calc(100vh - 253px);
}

header .dropdown-menu.show,
.toolbar-buttons .dropdown-menu.show {
    border: none !important;
    padding: 0px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    font-size: 15px;
}


.page-link.active,
.active>.page-link {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: #fff !important;
}

.page-link:focus {
    box-shadow: 0 0 0 0 !important;
}

.page-link {
    color: $primary-color !important;
}

.admin-section-icon {
    cursor: pointer;

}

.tabs-section {
    .nav-tabs .nav-link.active {
        border-bottom: 2px solid $primary-color !important;
        color: $primary-color !important;
        border: none;
        background-color: transparent !important;
        font-weight: 500 !important;
        font-size: 15px !important;
    }

    .nav-tabs .nav-link {
        border: none !important;
        color: #41464b !important;
        font-weight: 500 !important;
        font-size: 15px !important;

    }

    .accordion-button {
        background: $primary-color !important;
        color: #fff !important;
        padding: 15px !important;
        font-size: 13px !important;
    }

    .accordion-button:focus {
        box-shadow: 0 0 0 0 !important;
    }

    .range-accordion .btn-link1 {
        width: 100% !important;
        text-decoration-line: none;
    }

    .accordion-button::after {
    background-image: url(../images/arrow-down.png);
        transform: rotate(-90deg) !important;
    }

    .accordion-button:not(.collapsed)::after {
        transform: rotate(180deg) !important;
        background-image: url(../images/arrow-up.png);
    }

    .accordion-body {
        padding: 16px !important;
    }
  
}




/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.sidebar {
    margin: 0;
    padding: 0;
    width: 280px;
    background-color: #ffff;
    position: fixed;
    height: 100%;
    border-right: 1px solid #cccc;
    overflow: auto;
    transition: left 0.3s ease-in-out;

}

.sidebar a {
    display: block;
    color: #313131 !important;
    padding: 10px;
    border-bottom: 1px solid #cccc;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
}

.sidebar a.active {
    background-color: #1f88e5 !important;
    color: #fff !important;
}

.settings.sidebar a.active {
    background-color: #1f88e5 !important;
    color: #fff !important;
}

.group-sidebar-header {
    position: sticky;
    top: 0;
    z-index: 1;
}

.sidebar-btn {
    font-size: 13px !important;
    border-color: transparent;
    padding: 4px 7px !important;
}

.sidebar-btn.delete-btn {
    background-color: #dc2f2f !important;
    border-color: #dc2f2f !important;
    border-radius: 0;
}

.group-list {
    max-height: calc(100vh - 130px);
    overflow: auto;
}

.hamburger-menu {
    display: none;
}

@media (max-width: 800px) {
    .hamburger-menu {
        display: inline !important;
    }

    .hamburger-btn {
        padding: 10px !important;
    }

}

div.content {
    margin-left: 280px;
}

@media screen and (max-width: 800px) {
    .sidebar {
        margin-top: 0px;
        width: 280px;
        position: absolute;
        height: calc(100vh - 62px);
        border-right: 1px solid #cccc;
        transition: 1.5s;
    }

    div.content {
        margin-left: 0;
    }

    .main-mt {
        margin-top: 1px !important
    }
}

.ng-select.ng-select-single .ng-select-container {
    z-index: 1 !important;
}

.autocomplete-container .suggestions-container {
    z-index: 111 !important;
}

.ng-autocomplete {
    width: 100% !important;
    max-width: 600px !important;
    display: table;
    margin: 0 auto;
}

.autocomplete-container .input-container input {
    border: 1px solid #ced4da !important;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
    border-radius: 0.375rem !important;
}

.autocomplete-container {
    box-shadow: 0 0 0 0 !important;
}

.search-icon-files {
    position: absolute;
    top: -8px;
    left: 10px;
}

.pl-35 {
    padding-left: 35px !important;
}

ngb-progressbar .progress-bar {
    background-color: $primary-color !important;
}

.details.word-break {
    word-break: break-all;
}

.assign-table {
    max-height: 350px;
    overflow: auto;

    table {
        border-radius: 4px;
    }
}


.breadcrumb {
    .breadcrumb-item a {
        color: $primary-color !important;
        text-decoration: none !important;
        font-weight: 500 !important;
    }

    .breadcrumb-operater {
        font-weight: 800;
        font-size: 15px;
    }

    .tootip-sec {
        position: absolute;
        visibility: hidden;
        z-index: 10;
    }

    .more-option:hover .tootip-sec {
        visibility: visible;
    }
}
// Context css starts
.context-menu {
    position: absolute;
    border-radius: 4px;
    padding: 0;
    width: auto;
    background-color: #fff;

    li {
        padding: 8px 20px;
        transition: 0.3s ease-in-out;

        &:hover {
            background-color: #eee;
            cursor: pointer;
        }
    }

    i {
        width: 20px;
    }

    .fa-solid {
        color: #000000c7;
    }

    .cutPaste {
        opacity: 0.3 !important;
        cursor: default !important;
    }

    .class-disable {
        opacity: 0.5;
        pointer-events: none !important;
    }
}


.gsutil-sftp-heading.mt-100 {
    padding: 18px;
}

.gsutil-sftp-heading {
    color: #1f88e6;
}
.visibility{
    visibility: hidden;
}  

.gsutil-sftp-file {

    h5 {
        color: #455a64;
    }

    dd,
    p,
    dt {
        color: #67757c;
    }

    dd a {
        text-decoration: none !important;
        color: #1f88e6;
    }

    .gsutil ul {
        list-style-type: circle;
    }
}

// Tree view scss starts 

.sidebar-tree-view {
    ul li {
        list-style: none ;
        padding: 5px 10px;
    }
    .pl-40{padding-left: 40px;}
    
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      
      li {
        padding-left: 1rem;
        position: relative;
      }
      
      li:before {
        content: '';
        position: absolute;
        left: -0.5rem;
        top: 5px;
        bottom: 5px;
        border-left: 1px solid #ccc;
      }
      
      li:last-child:before {
        border-left: none;
      }
      
      li.collapsed:before {
        content: '+';
        color: blue;
        cursor: pointer;
      }
      
      li.collapsed ul {
        display: none;
      }
      
      li.expanded:before {
        content: '-';
        color: blue;
        cursor: pointer;
      }
      
      li.expanded ul {
        display: block;
      } 
}

.superscript-text{
    color: #8d8282;
    font-size: 10px;
}
.filemanager-trash{
bottom: 0 !important;
}

.img-fluid {
    width: 100%;
    max-height: 600px;
    object-fit: contain;
}

.loader-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.spinner-border {
    color: #007bff;
}

.modal-body.file-preview-modal {
   min-height: 150px;
}

.pdf-area {
    height: calc(100vh - 225px);
    width: 100%;
}

.preview-file-table-responsive {
    height: calc(100vh - 250px);
    width: 100%;
    overflow: auto;
}
.image-close {
    position: absolute;
    top: 16px;
    right: 8px;
    z-index: 9999;
    color: black;
  }

  .file-preview-container {
    display: flex;
    align-items: center;
    gap: 10px; 
  }
  
  .file-name {
    white-space: nowrap;
    overflow: hidden; 

  }
  
  .preview-item-list-view {
    width: 25px; 
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  
    &.folder img {
      width: 25px; 
      height: 25px; 
      object-fit: contain;
    }
  
    img, iframe {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  
    &.image img {
      border-radius: 4px;
    }
  
    .video-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
  
    
    }
  }
  .version-text{
    right: 9px;
  }