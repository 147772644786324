.activity-editor {
  height: calc(100vh - 200px) !important;
  overflow: auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #fff;
  padding: 16px;
  
  .json-form {
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .panel {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    margin-bottom: 16px;
    overflow: hidden;
    transition: all 0.2s ease;
    
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }
  }
  
  .panel-title {
    background-color: #f9fafb;
    padding: 12px 16px;
    font-weight: 600;
    color: #374151;
    display: flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #f3f4f6;
    }
  }
  
  .chevron-icon {
    margin-right: 10px;
    transition: transform 0.2s ease;
    color: #6b7280;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.fa-chevron-down {
      transform: rotate(0deg);
    }
    
    &.fa-chevron-right {
      transform: rotate(-90deg);
    }
  }
  
  .panel-content {
    padding: 16px;
    background-color: #ffffff;
    transition: all 0.3s ease-out;
  }
  
  .field-group {
    margin-bottom: 16px;
  }
  
  .field-label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    color: #4b5563;
    font-size: 14px;
  }
  
  .field-input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 14px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: #1e88e5;
      box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
    }
    
    &:hover:not(:focus) {
      border-color: #9ca3af;
    }
  }
  
  .add-button {
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 500;
    margin-left: auto;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
    
    &:before {
      content: "+";
      margin-right: 4px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  
  .child-content {
    margin-left: 20px;
    border-left: 3px solid #1e88e5;
    padding-left: 16px;
    
    .panel {
      box-shadow: none !important;
    }
  }
  
  // Empty state styling for the children array
  .empty-array {
    padding: 20px;
    text-align: center;
    color: #6b7280;
    background-color: #f9fafb;
    border-radius: 6px;
    border: 1px dashed #d1d5db;
    margin-bottom: 16px;
    
    p {
      margin: 0 0 10px 0;
      font-size: 14px;
    }
  }
  
  // Boolean field styling
  .boolean-field {
    display: flex;
    align-items: center;
    
    input[type="checkbox"] {
      margin-right: 8px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}