.page-preview{
    font-size: 13px ;
    line-height: 1.6 ;
    word-wrap: break-word ;
    p{
        font-size: 13px !important;

    }
}

// .page-preview {
//     margin: 10px;
//     // max-width: 700px;
//     width: 100%;
//     .page-title {
//       margin-bottom: 15px !important;
//       font-weight: 800 !important;
//       color: #000000 !important;
//   }
  
//   p {
//       margin: 5px 0px 15px 0 !important;
//       max-width: 700px !important;
//       font-size: 13px !important;
//   }
  
//   p.summary {
//     padding: 15px 40px;
//     font-size: 11px !important;
//   }
//   li {
//    font-size: 13px !important;
//   }
//   .alt-text {
//     font-style: italic;
//     font-size: 11px;
//     text-align: center;
//   }
  
//   .img-wrapper {
//     display: flex !important;
//     justify-content: center !important; 
//     align-items: center !important; 
//     flex-direction: column;
//     overflow: hidden;
//   }
//   img {
//        display: flex;
//        justify-content: center;
//        align-items: center;
//        overflow: hidden;
//        margin-bottom: 5px !important;
//        max-width: 100%;
//   }
  
//   table {
//       margin: 15px 0px !important;
//       border: 1px solid #c2c2c2 !important;
//       padding: 5px !important;
//       width: 660px;
//       overflow-x: auto;
//       display: block;
//   }
//   table th, table td {
//       border: 1px dashed #c2c2c2 !important;
//       padding: 5px 5px !important;
//   }
//   table.form-table {
//       border-width: 0px !important;
//   }
//   table.form-table td {
//       border-width: 0px !important;
//   }
  
//   table.form-table td.label {
//       padding-right: 15px !important;
//       text-align: right !important;
//       width: 35% !important;
//   }
  
//   table.form-table td.value {
//       padding-left: 15px !important;
//       text-align: left !important;
//       width: 65% !important;
//   }
  
//     table.form-table tr.odd td {
//         background-color: #f4f4f4 !important;
//     }
//     table.form-table tr.even td {
//         background-color: #eaeaea !important;
//     }
//     h3 {
//       margin-top: 21px;
//       font-size: 21px !important;
//     }
//     h4 {
//       margin-top: 30px;
//       font-size: 15px !important;
//     }
//   }

//   app-activity .page-preview{
//     p{
//       max-width: 100% !important;
//     }
//   } 